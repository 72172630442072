#windy {
    width: 100% !important;
    height: 400px !important;
    min-height: calc(50vh - 210px) !important;
    max-height: calc(100vh - 210px) !important;
    font-family: var(--ion-font-family);
    color: #333;
    font-weight: 600;
}

#windy #bottom,
#windy #embed-zoom,
#windy #mobile-ovr-select,
#windy #logo-wrapper {
    z-index: 10000 !important;
}


#windy #mobile-ovr-select {
    display: none !important;
}
#windy #embed-zoom {
    bottom: 106px !important;
    top: unset !important;
    right: 19px;
}
#windy #embed-zoom .zoom-ctrl {
    background-color: #e5e5e5;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.75);
}
#windy #embed-zoom .zoom-ctrl:hover {
    opacity: 1;
    background-color: #e5e5e5;
}

#windy #logo-wrapper #logo {
    top: -5px !important;
    left: 68px !important;
    transform: scale(0.8, 0.8);
}
.platform-mobile #windy #logo-wrapper #logo {
    left: -2px !important;
}
.platform-tablet #windy #logo-wrapper #logo {
    left: 67px !important;
}

#windy #legend-mobile {
    color: #fff;
    font-weight: 500;
}

#windy .play-pause {
    color: #666;
}
#windy .play-pause::before {
    top: 0.11em;
}

#windy .timecode .box {
    background-color: #e5e5e5;
    color: #333;
}

#windy .picker-close-button {
    display: none;
}



.touch-device #windy #embed-zoom {
    display: none !important;
}


.windy-nav-button-container {
    z-index: 10001;
    position: absolute;
    right: var(--modal-padding-horizontal);
}

.windy-nav-button {
    position: relative;
    top: -105px;
    left: -13px;
    cursor: pointer;
    background-color: #e5e5e5;
    color: #666;
    width: 36px;
    height: 36px;
    border-radius: 20px;
    padding: 5px 0px 0px 2px;
    box-shadow: 0 0 4px 0 black;
}

.windy-icon {
    font-size: 14px;
    font-family: 'iconfont';
    font-weight: normal;
    padding-right: 8px;
}

.windy-layer-buttons {
    position: absolute;
    z-index: 10000 !important;
    right: var(--modal-padding-horizontal);
    padding: 12px 12px 0px 0px;
}
/* .windy-layer-buttons > div {
    background-color: rgba(255,255,255,0.75);
    height: 24px;
    line-height: 24px;
    width: 88px;
    border-radius: 15px 0px 0px 15px;
    vertical-align: middle;
    text-align: left;
    padding-left: 10px;
    margin-top: 4px;
    font-weight: 500;
    font-size: 11px;
} */
.windy-layer-buttons > span {
    line-height: 20px;
    position: relative;
    top: 2px;
}
.windy-layer-buttons .sea-input {
    height: 30px;
    width: 100px;
    --padding-start: 10px;
    box-shadow: 0 0 4px 0 black;
    background-color: #e5e5e5;
}
.windy-layer-buttons .sea-select::part(icon) {
    left: -4px;
}
.windy-layer-buttons .sea-select::part(icon) .select-icon-inner {
    margin-top: -1px;
}

/* 
.forecast-test-table {
    font-size: 13px;
    margin-top: 80px;
}
.forecast-test-table thead td {
    font-weight: 600;
}
.forecast-test-table td {
    padding-right: 8px;
    text-align: right;
}
.forecast-test-table tbody td {
    padding-left: 8px;
    font-family: 'Courier New', Courier, monospace;
}
.forecast-test-table tbody td span {
    font-size: 11px;
}
 */


