.corrective-actions-history .sea-row-history.headings-history {
    padding-top: 0px;
    margin-top: 0px;
}

.log-month {
    font-size: 13px;
    padding: 18px 18px 0px 18px;
    margin: 6px 0px 0px 0px;
}

.corrective-actions-history .sea-card.sea-row-history {
    /* min-height: unset; */
    padding: 5px 4px;
    margin-top: 5px;
}
.corrective-actions-history .sea-row-history > div {
    padding: 3px 8px;
}

.corrective-actions-history .sea-row-history > div:nth-of-type(1) { /* Date */
    flex: 0 1 100px;
}

.corrective-actions-history .sea-row-history > div:nth-of-type(2) { /* Title */
    flex: 1 1 0;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.corrective-actions-history .sea-row-history > div:nth-of-type(3) { /* Action */
    flex: 0 1 90px;
}

.corrective-actions-history .sea-row-history > div:nth-of-type(4) { /* Assigned */
    flex: 0.5 1 0;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.corrective-actions-history .sea-row-history > div:nth-of-type(5) { /* When Completed */
    flex: 0 1 110px;
}

.corrective-actions-history .sea-row-history > div:nth-of-type(6) { /* Vessels */
    flex: 0.5 1 0;
    cursor: pointer;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.corrective-actions-history.hide-vessels .sea-row-history > div:nth-of-type(6) { /* Vessels */
    display: none;
}

.corrective-actions-history .sea-row-history.headings-history > div { /* Headings */
    cursor: initial;
    white-space: wrap;
}


@media screen and (max-width: 550px) {
    .corrective-actions-history .sea-card.sea-row-history {
        padding: 2px 10px 8px;
    }
    .corrective-actions-history .sea-row-history > div:nth-of-type(1)::after {
        content: attr(data-fullname);
    }
    /* .corrective-actions-history .sea-row-history > div:nth-of-type(2) {
        position: absolute;
        top: 20px;
        opacity: 1;
    } */
    .corrective-actions-history .sea-row-history > div:nth-of-type(4) {
        display: none;
    }
    .corrective-actions-history .sea-row-history > div:nth-of-type(5)::after {
        content: attr(data-fullname);
    }
}
