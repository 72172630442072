.training-task-user-history .sea-row-history > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;   
}
.training-task-user-history .sea-row-history > div:nth-of-type(2) { /* Date */
    flex: 0 1 110px;    
}
.training-task-user-history .sea-row-history > div:nth-of-type(3) { /* Trainer */
    flex: 0 1 160px;
}
.training-task-user-history .sea-row-history > div:nth-of-type(4) { /* Notes */
    flex: 1 1 0;
    cursor: pointer;
}
.training-task-user-history .sea-row-history > div:nth-of-type(5) { /* Trash */
    flex: 0 1 35px;
    padding: 10px 10px;
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .training-task-user-history .sea-card.sea-row-history > div::before {
        width: 70px;
    }

    .training-task-user-history .sea-row-history > div:nth-of-type(2),
    .training-task-user-history .sea-row-history > div:nth-of-type(3) {
        padding-right: 30px;
        text-align: left;
    }

    .training-task-user-history .sea-row-history > div:nth-of-type(2)::before {
        content: 'Date';
        padding-left: 0px !important;
    }
    .training-task-user-history .sea-row-history > div:nth-of-type(3)::before {
        content: 'Trainer';
    }
    .training-task-user-history .sea-row-history > div:nth-of-type(4) {
        white-space: normal;
        padding-left: 120px;
    }
    .training-task-user-history .sea-row-history > div:nth-of-type(4)::before {
        content: 'Notes';
        position: absolute;
        left: 0px;
    }

    .training-task-user-history .sea-row-history > div:nth-of-type(5) {
        height: 0px;
        padding: 0px;
    }

    .training-task-user-history .sea-row-history > div:nth-of-type(5) > div {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
