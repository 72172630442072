
.timeZones .sea-card.sea-row {
    padding: 4px 4px 4px 4px;
    min-height: 40px;
}

/* Column settings */
.timeZones .sea-row > div:nth-of-type(1) { /* Timezone identifier */
    flex: 0 1 300px;
}
.timeZones .sea-row > div:nth-of-type(2) { /* Status */
    flex: 0 1 200px;
}
.timeZones .sea-card.sea-row > div:nth-of-type(3) { /* Current Time */
    flex: 1 1 0;
    font-family: monospace;
}
