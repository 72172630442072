.sea-label {
  height: 21px;
  overflow: visible;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--label-text);
  font-size: 11px;
  line-height: 0.95em;
  text-align: left;
}
div > .sea-label {
  color: var(--label-text);
}
.sea-label.grey-zone {
  margin-bottom: -4px;
}
.sea-label.white-zone {
  background-color: transparent;
}

.sea-label.blue {
  height: unset;
  color: var(--ion-color-primary);
  margin-bottom: -12px;
}
.custom-form-container .sea-label {
  text-transform: none;
}