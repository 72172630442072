.sea-tab {
  --border-radius: 0px;
  --background: #9c9ea7;
  --background-activated: var(--ion-color-primary);
  --background-hover: transparent;
  --padding-start: 4px;
  --padding-end: 4px;
  --box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.025);
  width: 162px;
  margin: 0px;
  letter-spacing: 1%;
  white-space: normal;
  height: 45px;
  transition: all 0.1s;
}
.sea-button.sea-tab {
  --overflow: visible;
}
.sea-tabs-container.mini .sea-tab {
  width: 128px;
  height: 40px;
  font-weight: 500;
  font-size: 12px !important;
}
.sea-tabs-container.micro .sea-tab {
  width: 100px;
  height: 34px;
  font-weight: 500;
  font-size: 12px !important;
}
.sea-tabs-container.full-width .sea-tab {
  width: 100%;
}

.sea-tab::part(native) {
  line-height: 1.2em;
}
/* .mouse-device .sea-tab {
  width: 170px;
} */
.tab-selected .sea-tab {
  --background: var(--ion-color-primary);
  --border-style: solid;
  --border-width: 0px 0px 3px 0px;
  --border-color: #fff;
  --box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  height: 45px;
}
.tab-selected .sea-tab.forms {
  --border-style: none;
}

/* errors */
.tab-selected .sea-tab.has-errors {
  --background: var(--ion-color-danger);
  --background-activated: var(--ion-color-danger);
}
.sea-tab.has-errors {
  --background: rgba(var(--ion-color-danger-rgb), 0.5);
}

.sea-tabs-group .notification-number {
  position: absolute;
  right: -6px;
  top: -4px;
  background-color: var(--ion-color-danger);
  color: #fff;
  min-width: 24px;
  height: 24px;
  border-radius: 24px;
  text-align: center;
  padding: 5px 3px 0px 3px;
  white-space: nowrap;
}

.sea-tabs-container ion-button.disabled {
  pointer-events: all;
  cursor: default;
}
.sea-tabs-container ion-button.disabled::part(native) {
  cursor: default;
}

.tab-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.sea-button.sea-tab.draggable::part(native) {
  cursor: grab !important;
}

.sea-button.sea-tab.draggable::part(native) * {
  cursor: inherit !important;
}

.drag-handle {
  cursor: grab;
  user-select: none;
  font-size: 1.2em;
  color: #ccc;
  opacity: 0.8;
}

.drag-handle ion-icon {
  margin-left: -6px;
  margin-right: -6px;
}

.drag-handle:active {
  cursor: grabbing;
}

.tab-content-draggable {
  flex: 1;
  display: -webkit-box;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical; 
  line-height: 1.2em;
  max-height: 2.4em;
  white-space: normal;
  cursor: grab;
}

@media screen and (max-width: 850px) {
  .sea-tab {
    font-size: 12px !important;
    width: 150px;
  }
  .sea-tabs-container.mini .sea-tab {
    width: 120px;
  }
  .sea-tabs-container.micro .sea-tab {
    width: 90px;
  }
}