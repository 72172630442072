.vessel-divisions-row {
    padding: 4px 0px;
}
.vessel-divisions-row > div:nth-of-type(1) { /* Vessel name */
    flex: 1 1;
    align-self: center;
}
.vessel-divisions-row > div:nth-of-type(2) { /* Divisions  */
    flex: 1 1;
}

.vessel-divisions-row .sea-select {
    height: 44px;
}
