:root {
  --safe-inset-top: 0px;
  --safe-inset-bottom: 0px;
  --safe-inset-left: 0px;
  --safe-inset-right: 0px;
  --safe-inset-top: env(safe-area-inset-top);
  --safe-inset-bottom: env(safe-area-inset-bottom);
  --safe-inset-left: env(safe-area-inset-left);
  --safe-inset-right: env(safe-area-inset-right);
  --sea-font-family: 'Montserrat', sans-serif;
  --grid-gutter: 0px;
  --grid-row-spacing: 0px;
  --max-width: 100%;
  --header-bar-height: 44px;
  --footer-height: 64px;
  --side-bar-width: 58px;
  --side-menu-width: 265px;
  --side-width: var(--side-menu-width); /* space taken up by menu depending on mode: hidden|bar|menu */
  --bg-grey: #7e808b;
  --bg-grey-rgb: 126, 128, 139;
  --bg-header: #373946;
  --icon-grey: #bfc4ca;
  --light-blue: #4076B0;
  --yellow-orange: #FFC107;
  --bg-completed: #3caf6a;
  --bg-draft: #cef2fe;
  --bg-warn-rgb: 253, 243, 209;
  --bg-fail-rgb: 245, 215, 215;
  --text-on-white: var(--bg-header);
  --bg-margins: var(--ion-color-light);
  --text-on-grey: #DCDDE1;
  --line-on-grey: rgba(255, 255, 255, 0.6);
  --label-text: #7f818c;
  --input-height: 52px;
  --input-border-color: #DCDDE1;
  --input-border-radius: 5px;
  --tiny-thumb-size: 40px;
  --max-table-width: 3840px;
  --mobile-status-gap: 86px;
  --mobile-status-icon-gap: 22px;
  /*
  --bg-dashboard-block-color: #545666;
  --bg-dashboard-block-rgb: 84, 86, 102;
  --bg-dashboard-row-color: #656878;
  --bg-dashboard-row-rgb: 101, 104, 120;
  */
  --bg-dashboard-row-color: #545666;
  --bg-dashboard-row-rgb: 84, 86, 102;
  --bg-dashboard-block-color: #656878;
  --bg-dashboard-block-rgb: 101, 103, 118;
  /* --bg-view-only: #e0f0ff; */
  --bg-view-only: #eaedf0;
  --bg-view-only-rgb: 234, 237, 240;
  --responsive-transition-time: 0.25s;
  --responsive-transition-easing: ease-out;
  --ion-grid-columns: 12;
  --grid-gutter: 14px;
  --grid-row-spacing: 22px;
  --resize-side-bar-height: 0px;
  --scrollbar-width: 0px;
}
.platform-windows {
  --scrollbar-width: 17px;
}

@media screen and (min-width: 851px) and (max-width: 1301px) {
  /* resize-side-bar only relevant if not too thin or wide */
  :root {
    --resize-side-bar-height: 30px;
  }
  .touch-device {
    --resize-side-bar-height: 44px;
  }
}
body {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: var(--bg-header);
  --ion-default-font: var(--sea-font-family);
  --ion-font-family: var(--sea-font-family);
  --ion-grid-padding: 0px;
  --ion-grid-column-padding: 0px;
}
ul {
  padding-inline-start: 30px;
}
.ion-page {
  background-color: var(--ion-color-light);
}
ion-col { /* Fixing what seems like a bug that allows columns to go beyond 100% width */
  max-width: 100% !important;
}
/* Fixing no scrolling for dropdowns */
.popover-viewport.sc-ion-popover-ios,
.popover-viewport.sc-ion-popover-md {
  overflow: auto;
}
/* Fix: Ionic popover not working with Chrome 114. See: https://stackoverflow.com/questions/76376324/ion-select-popover-not-working-chrome-114 */
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
h1, h2, h3 {
  font-weight: 700;
  letter-spacing: 1px;
}
h1 {
  font-size: 36px;
  margin: 5px 0px;
}
h2 {
  font-size: 24px;
  margin: 10px 0px 8px 0px;
}
h3 {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0;
}
h2.for-view-modal {
  color: var(--text-on-white);
  font-size: 18px;
  margin-bottom: 16px;
}
.sea-card h2 {
  margin: 0px;
}
.pushy {
  opacity: 1;
  transition: all .2s ease-in-out;
  cursor: pointer;
}
.pushy:active {
  opacity: 0.8;
  transition: all .1s ease-in-out;
  transform: scale(0.95);
}
.submit-enter {
  position: absolute;
  visibility: hidden;
  left: -1000px;
}

.sea-fullscreen-view {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 10000;
  text-align: center;
  background-color: rgba(0,0,0,0.95);
}
.sea-fullscreen-view img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  object-fit: scale-down;
  touch-action: manipulation;
  position: relative;
  left: 0px;
}

.sea-fullscreen-view > .box {
  /* z-index: 1; */
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
}

.sea-fullscreen-view > .box.pdf-fullscreen {
  /* z-index: 2; */
  position: absolute;
  /* padding: 10vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.sea-fullscreen-view .off {
  display: none;
}
.sea-fullscreen-view .animate {
  transition: left 0.35s ease-out;
}
.sea-fullscreen-view .slide-center {
  left: 0;
}
.sea-fullscreen-view .slide-left {
  left: -100%;
}
.sea-fullscreen-view .slide-right {
  left: 100%;
}

.sea-fullscreen-view .non-image {
  width: 200px;
  height: 200px;
  border-radius: 10px;
}
.sea-fullscreen-view .control {
  z-index: 3;
  cursor: pointer;
  position: absolute;
  color: var(--ion-color-light);
  padding: 20px;
  filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 0.66));
}
.sea-fullscreen-view .control.prev-next {
  top: 50%;
  margin-top: -100px;
}
.control.top {
  top: 0px;
  margin-top: var(--safe-inset-top);
}
.control.left {
  left: 0px;
}
.control.right {
  right: 0px;
}
.control.bottom {
  bottom: 0px;
  margin-bottom: var(--safe-inset-bottom);
}

.sea-scrollable {
  scrollbar-color: var(--bg-grey) var(--bg-header);
}
ion-modal, ion-popover {
  scrollbar-color:  var(--input-border-color) #9c9ea7;
}

.sea-fullscreen-view .controls .trash {
  margin: 20px 0px 0px 20px;
  background-color: rgba(50,50,50,0.8);
  width: 120px;
  height: 120px;
  padding: 30px;
  border-radius: 60px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}
.sea-fullscreen-view ion-icon {
  align-self: center;
  opacity: 0.65;
  filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 0.66));
}
.sea-uploading {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.95);
}
.sea-uploading .text {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  color: var(--text-on-white);
  font-size: 13px;
  font-weight: 500;
}
.sea-uploading .sea-spinner {
  --color: var(--ion-color-primary);
  margin: -64px 0 0 -32px;
}
.sea-uploading .progress {
  margin: 20px auto 0px auto;
  padding: 0px 14px;
  max-width: 400px;
}
.sea-update-local-data.sea-uploading .text {
  top: calc(50% - 40px);
}
.sea-update-local-data.sea-uploading .sea-spinner {
  margin-top: -104px;
}
.sea-missing-file {
  margin: 0px 0px 12px 0px;
  font-size: 16px;
}

.conceal {
  opacity: 0;
  transition: opacity 0.2s;
}
.reveal {
  opacity: 1;
  transition: opacity 0.2s;
}
.faded {
  opacity: 0.5;
}
.conceal.faster, .reveal.faster {
  transition: opacity 0.1s;
}
.conceal.slooow, .reveal.slooow {
  transition: opacity 1s;
}
.pulse {
  position: relative;
  top: 0px;
  left: 0px;
  opacity: 1.0;
  transition: all 0.2s linear;
}
.pulse-in, .pulse-left, .pulse-right, .pulse-down {
  position: relative;
  opacity: 0;
}
.pulse-right {
  left: -12px;
}
.pulse-left {
  left: 12px;
}
.pulse-down {
  top: -12px;
}
.pulse-start {
  opacity: 0.2;
}
.pulse-end {
  opacity: 1.0;
  transition: opacity 0.75s;
}

.sea-fullscreen-view.modal-box {
  background-color: rgba(0,0,0,0.33);
  justify-content: center;
  align-items: center;
}
.sea-fullscreen-view.modal-box .center-box {
  max-width: 80%;
  max-height: 80%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  transition: all 0.2s;
}
.sea-fullscreen-view.modal-box.conceal {
  opacity: 0;
  transition: opacity 0.2s;
}
.sea-fullscreen-view.modal-box.reveal {
  opacity: 1;
  transition: opacity 0.2s;
}
.sea-fullscreen-view.modal-box.conceal .center-box {
  transform: scale(0.8);
}
.sea-fullscreen-view.modal-box.reveal .center-box {
  transform: scale(1);
}
.sea-modal h2 span:has(.icon)  {
  display: flex;
  flex-direction: row;
}
.sea-modal h2 .critical {
  display: inline-flex;
  color: var(--ion-color-danger);
  padding-left: 12px;
}

.sea-modal h2 .hidden {
  display: none;
}


.columns {
  width: 100%;
  display: flex;
}
.columns .right {
  text-align: right;
  flex-grow: 1;
}
.columns.wrap, .columns .right.wrap {
  flex-wrap: wrap;
}
.rows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-buttons {
  display: flex;
  justify-content: flex-end;
}
.right-buttons .spacer, .view-modal-buttons .spacer {
  height: 40px;
  width: 8px;
}
.right-buttons .line-divider {
  align-self: center;
  height: 40px;
  width: 1px;
  margin: 0px 10px 0px 8px;
  background-color: #d0d2d6;
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.page-head {
  display: flex;
  flex-direction: row;
}
.page-head .actions {
  flex-grow: 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.sea-fill-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.alert-wrapper {
  max-width: 500px !important;
  line-height: 1.5em;
}
.alert-title {
  letter-spacing: normal;
}

.sea-spinner {
  width: 64px;
  height: 64px;
  margin: -32px 0 0 -32px;
  position: absolute;
  top: 50%;
  left: 50%;
  --color: var(--icon-grey);
}

.sea-spinner-2 {
  width: 64px;
  height: 64px;
  --color: var(--icon-grey);
}

.sea-spinner.inline {
  position: absolute;
  width: 22px;
  height: 22px;
  right: 0%;
  left: 135%;
  top: 100%;
  opacity: 1;
}

.sea-spinner.overdue-stat {
  width: 22px;
  height: 22px;
  position: relative;
  top: 0px;
  left: 15px;
  --color: var(--ion-color-primary);
  opacity: 1;
}

.grey-zone {
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light);
}
.blue-zone {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-light);
}
.white-zone {
  background-color: var(--ion-color-light);
  color: var(--bg-header);
}
.gold-zone {
  color: var(--bg-header);
}

.light-blue-zone {
  background-color: var(--ion-color-secondary);
}

/* Legal text */
.legal h1 {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 16px;
  position: relative;
}
.legal h1::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0px;
  bottom: -3px;
  background-color: #888;
  margin: 6px 0px 0px 0px;
}
.legal ol {
  padding-inline-start: 30px;
  list-style-type: none;
}
.legal li {
  position: relative;
  padding-bottom: 0.9em;
}
.legal li::marker {
  display: none;
}
.legal li::before {
  content: attr(data-n);
  position: absolute;
  left: -30px;
}
.legal ol ol {
  padding-inline-start: 40px;
}
.legal ol ol ol {
  padding-inline-start: 50px;
}
.legal ol ol li::before {
  left: -40px;
}
.legal ol ol ol li::before {
  left: -50px;
}
.legal .columns div {
  padding-right: 10px;
}

/* Grids */
.card-grid {
  --grid-gutter: 10px;
}
.form-grid {
  --grid-gutter: 14px;
  --grid-row-spacing: 22px;
}
.form-spacer {
  width: 25px;
  height: 25px;
}
.auth-form-grid {
  --grid-row-spacing: 16px;
}
ion-col {
  padding-right: var(--grid-gutter);
  padding-bottom: var(--grid-row-spacing);
}
ion-row {
  margin-right: calc(-1 * var(--grid-gutter));
}
ion-grid {
  margin-bottom: calc(-1 * var(--grid-row-spacing));
}
ion-router-link {
  text-decoration: underline;
}
ion-icon {
  pointer-events: none;
}
.grid-row-spacer {
  height: var(--grid-row-spacing);
}

/*
.sea-table ion-row {
  margin: 10px 0px 0px 0px;
  padding: 15px 18px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  color: var(--text-on-white);
}
.sea-table ion-row.headings {
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 0px 15px;
}
.sea-table ion-row.warn, .sea-table ion-row.highlighted {
  background-color: #fff3cd;
}
.sea-table ion-row.error {
  background-color: #fbd6d6;
}
*/

/*
.sea-table {
  display: table;
  margin: 10px 0px 0px 0px;
  padding: 15px 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  color: var(--text-on-white);
  table-layout: fixed;
  width: 100%;
}
.sea-table .row {
  display: table-row;
}
.sea-table .row > div {
  display: table-cell;
  padding: 0px 8px;
}
.sea-table.debug .row > div:nth-of-type(even) {
  background-color: #aaa;
}
.sea-table.headings {
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}
*/

.sea-card {
  margin: 10px 0px 0px 0px;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  color: var(--text-on-white);
  width: 100%;
  position: relative;
  min-height: 50px;
}
/* .sea-card::after {
  content: none;
  background-image: url('/assets/right.png');
  background-size: 8px 14px;
  position: absolute;
  width: 8px;
  height: 14px;
  right: 16px;
  top: calc(50% - 7px);
} */
.sea-card.fault-reported {
  background-color: #EB3232 !important;
  color: #fff;
  font-weight: bold;
}
.sea-card.fail,
.sea-card.sea-row-history.fail {
  background-color: rgb(var(--bg-fail-rgb));
}
.sea-card.fail.fault,
.sea-card.sea-row-history.fail.fault {
  background-color: var(--ion-color-danger);
  color: white !important;
}
.sea-card.warn,
.sea-card.sea-row-history.warn {
  background-color: rgb(var(--bg-warn-rgb));
}
.sea-card.warn-medium,
.sea-card.sea-row-history.warn-medium {
  background-color: #ffe2b9;
}
.sea-card.warn-low,
.sea-card.sea-row-history.warn-low {
  background-color: #ffffe1;
}
.sea-card.warn-alt,
.sea-card.sea-row-history.warn-alt {
  background-color: #f2f9ff;
}
.sea-card.sea-row {
  cursor: pointer;
  padding: 10px 4px 10px 4px;
}
/* .sea-card.sea-row::after {
  content: '';
  background-image: url('/assets/right-primary.png');
} */
.sea-card.summary {
  border-radius: 24px;
}
.sea-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 13px;
}
.sea-row.headings {
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 10px 0px 0px 0px;
  padding: 15px 4px 0px 4px;
}
.sea-row > div {
  flex: 1 1 0;
  overflow-x: hidden;
  padding: 0px 8px;
  align-self: center;
}
.sea-row.headings > div {
  padding: 5px 8px;
  align-self: flex-end;
}
.sea-row > div.bold,
.sea-row-history > div.bold {
  font-weight: 700;
}
.sea-row.debug > div:nth-of-type(even) {
  background-color: #aaa;
}

.sea-error-report-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--ion-color-danger-rgb), 0.5);
  padding: 30px 20px;
}
.sea-error-report {
  padding: 32px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  padding: 20px 30px;
  max-width: 800px;
  margin: auto;
  max-height: 100%;
  overflow: auto;
  line-height: 1.4em;
}
.sea-error-report h3{
  color: var(--ion-color-danger);
  margin: 0px 0px 16px 0px;
}
.sea-blank {
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

/* History table when viewing an item */
.sea-row-history {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 13px;
}
.sea-row-history.headings-history {
  background-color: transparent;
  box-shadow: none;
  color: var(--bg-header);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 10px 0px 0px 0px;
  padding: 15px 4px 0px 4px;
  font-weight: 500;
}
.sea-row-history.headings-history > div {
  align-self: flex-end;
}
.sea-row-history.draft {
  font-style: italic;
  background-color: white !important;
}

.sea-row-history > div {
  flex: 1 1 0;
  overflow-x: hidden;
  padding: 0px 8px;
  align-self: center;
}
.clickable {
  cursor: pointer;
}
.sea-card.sea-row-history,
.sea-history-group {
  box-shadow: none;
  background-color: var(--bg-view-only);
  padding: 5px 4px;
}
.sea-card.sea-row-history.clickable {
  cursor: pointer;
  border: 1px solid var(--input-border-color);
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
}
.sea-history-group .sea-row-history {
  padding: 8px 4px 8px 4px;
}

/* Default column settings for history tables */
.sea-row-history > div:nth-of-type(1) { /* Date */
  flex: 0 1 85px;
}

/* Dashboard containers */
.sea-container {
  border-radius: 24px;
  box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.15);
}

/* images */
.tiny-thumb {
  display: block; /* removes descender whitespace below img */
  width: var(--tiny-thumb-size);
  height: var(--tiny-thumb-size);
  border-radius: 6px;
  object-fit: cover;
}

/* line */
.line {
  background-color: var(--line-on-grey);
  width: 100%;
  height: 1px;
  margin-top: 4px;
  margin-bottom: 8px;
}
.line-2 {
  background-color: var(--input-border-color);
  width: 100%;
  height: 1px;
  margin-top: 4px;
  margin-bottom: 8px;
}
.form-line {
  background-color: var(--input-border-color);
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 2px;
}
.vertical-line {
  background-color: var(--line-on-grey);
  height: 40px;
  width: 1px;
  align-self: center;
  margin-right: 10px;
  margin-left: 10px;
}

/* Arrow icon to view item modal e.g. " > " */
.view-item-arrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--ion-color-primary);
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  padding: 15px 0px !important;
}
.more-info-dropdown {
  cursor: pointer;
  color: var(--ion-color-primary);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Category heading for table list */
.category-heading {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2px;
  background-color: #595c6e;
  padding: 6px 12px;
  margin-top: 32px;
  color: #fff;
}
.sea-modal-content .category-heading {
  background-color: #9395a5;
}
div.category-heading:nth-of-type(1),
div.category-heading:nth-of-type(2) {
  margin-top: 10px;
}

/* Viewing an item with a right side image */
.view-item-with-image {
  flex: 0 1 550px;
  margin-right: 20px;
  padding-right: 20px;
  margin-left: 0;
}

/* On View Modal button controls - parent div - Edit Item, Delete Item */
.view-modal-buttons {
  margin-top: 44px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  clear: both;
}
.view-modal-buttons .sea-link-button {
  line-height: 2.6em;
}
.view-modal-buttons .right {
  flex: 1 1 0;
  text-align: right;
}

.spacer-wide {
  width: 30px;
  height: 1px;
}

/* truncate string */
.truncate {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.truncate-1 {
  overflow: hidden;
  position: relative;
  line-height: 17px;
  max-height: 17px;
}
.truncate-1::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  height: 17px;
  background: linear-gradient(
      to right,
      rgba(var(--bg-dashboard-row-rgb), 0.3) 0%,
      rgba(var(--bg-dashboard-row-rgb), 1) 100%
  );
}
.truncate-2,
.truncate-3 {
  overflow: hidden;
  position: relative;
  line-height: 17px;
  max-height: 33px; /* (lines - 1) x line-height + gradient height */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
}
.truncate-3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  max-height: 50px;
}
/* .truncate-2::after,
.truncate-3::after {
  content: "";
  position: absolute;
  top: 17px;
  left: 0px;
  width: 100%;
  height: 16px;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 1) 100%
  );
}
.sea-card.sea-row-history.draft .truncate-2::after,
.sea-card.sea-row-history.draft .truncate-3::after {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.sea-card.sea-row-history .truncate-2::after {
  content: "";
  position: absolute;
  top: 17px;
  left: 0px;
  width: 100%;
  height: 16px;
}
.sea-card.sea-row-history .truncate-2::after,
.sea-card.sea-row-history .truncate-3::after,
.sea-history-group .sea-row-history .truncate-2::after,
.sea-history-group .sea-row-history .truncate-3::after {
  background: linear-gradient(
      to bottom,
      rgba(var(--bg-view-only-rgb), 0.3) 0%,
      rgba(var(--bg-view-only-rgb), 1) 100%
  );
}
.blue-zone .truncate-2::after,
.blue-zone .truncate-3::after {
  background: linear-gradient(
    to bottom,
    rgba(var(--bg-dashboard-row-rgb), 0.3) 0%,
    rgba(var(--bg-dashboard-row-rgb), 1) 100%
  );
}
.sea-card.warn .truncate-2::after,
.sea-card.warn .truncate-3::after {
  background: linear-gradient(
    to bottom,
    rgba(var(--bg-warn-rgb), 0.3) 0%,
    rgba(var(--bg-warn-rgb), 1) 100%
  );
}
.sea-card.fail .truncate-2::after,
.sea-card.fail .truncate-3::after {
  background: linear-gradient(
    to bottom,
    rgba(var(--bg-fail-rgb), 0.3) 0%,
    rgba(var(--bg-fail-rgb), 1) 100%
  );
}
.truncate-3::after {
  top: 34px;
} */

/* Drill History and Crew Training Task History table */
/* .drill-history .custom-history-table, 
.training-task-history .custom-history-table,
.action-log .custom-history-table,
.crew-sea-record .custom-history-table {
  background-color: var(--ion-color-light);
  border: 1px solid var(--input-border-color);
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.07);
  padding: 12px 10px;
  font-size: 13px;
  border-radius: 5px;
  margin-top: 10px;
} */

/* Tide Map */
@import url("https://unpkg.com/leaflet@1.5.1/dist/leaflet.css");
.leaflet-container {
  /* width: 100%;
  height: 100vh; */
  width: 450px;
  height: 300px;
}

@media screen and (max-width: 850px) { /* Tablet portrait and smaller */
  h1 {
    font-size: 30px;
    margin: 7px 0px;
  }
  /* h2 {
    font-size: 22px;
  } */
  /* h3 {
    font-size: 10px;
  } */
}

@media screen and (max-width: 550px) {
  :root {
    --ion-grid-columns: 6;
  }
}

@media screen and (max-width: 420px) {
  :root {
    --ion-grid-columns: 4;
  }
  .wider-420 {
    display: none;
  }
}
@media screen and (max-width: 350px) {
  :root {
    --ion-grid-columns: 3;
  }
}

@media screen and (max-width: 550px) { /* Card format */
  .truncate-2,
  .truncate-3 {
    display: block;
  }
  .category-heading {
    padding: 9px 10px;
  }
  .sea-row.headings,
  .sea-row-history.headings-history {
    display: none;
  }
  .sea-card.sea-row,
  .sea-card.sea-row-history {
    padding: 4px 8px 10px 8px;
    position: relative;
    flex-wrap: wrap;
    --status-label-padding-top: 5px;
  }
  .has-thumbs .sea-card.sea-row,
  .has-thumbs .sea-card.sea-row-history {
    min-height: 60px;
  }
  .sea-card.sea-row > div,
  .sea-card.sea-row-history > div {
    min-width: 100%;
    padding: 6px 0px 0px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: unset;
  }
  .not-card {
    display: none;
  }
  .sea-card.sea-row br {
    display: none;
  }
  .sea-card.sea-row .sea-status,
  .sea-card.sea-row-history .sea-status {
    display: inline-block;
    min-width: 80px;
    position: absolute;
    top: 8px;
    right: 9px;
    letter-spacing: 0.2px;
    font-weight: 500;
    line-height: 17px;
    padding: 0px 8px;
  }
  .sea-card.sea-row .sea-status.icon,
  .sea-card.sea-row-history .sea-status.icon {
    right: 0px;
    min-width: 20px;
  }
  .sea-card.sea-row .sea-status.icon > ion-icon,
  .sea-card.sea-row-history .sea-status.icon > ion-icon{
    font-size: 18px !important;
  }
  .sea-card.sea-row .sea-status.icon.bottom,
  .sea-card.sea-row-history .sea-status.icon.bottom {
    top: auto;
    bottom: 4px;
  }
  .sea-card.sea-row > div::before,
  .sea-card.sea-row-history > div::before { /* Card labels */
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 400;
    opacity: 0.9;
    width: 90px;
    display: inline-block;
    vertical-align: top;
    padding: 2px 0px 0px 0px;
    /* letter-spacing: 1px; */
  }

  .sea-card.sea-row-history {
    padding: 2px 10px 8px;
  }
  .sea-history-group .sea-row-history {
    padding: 8px 0px 8px 0px;
  }

  .has-thumbs .sea-card.sea-row > div::before,
  .has-thumbs .sea-card.sea-row-history > div::before {
    padding-left: 50px;
  }
  .sea-card.sea-row .sea-file-image {
    display: inline-block;
  }
  .has-status .sea-card.sea-row > div:nth-of-type(1) {
    padding-right: var(--mobile-status-gap);
  }
  .sea-card.sea-row .has-icon  {
    padding-right: var(--mobile-status-icon-gap);
  }
  .sea-card.sea-row .is-icon  {
    padding: 0;
  }
  .has-status.has-thumbs .sea-card.sea-row > div:nth-of-type(1),
  .has-status.has-thumbs .sea-card.sea-row-history > div:nth-of-type(1) {
    padding-right: 0px;
  }
  .has-status.has-thumbs .sea-card.sea-row > div:nth-of-type(2),
  .has-status.has-thumbs .sea-card.sea-row-history > div:nth-of-type(2)  {
    padding-right: var(--mobile-status-gap);
  }
  .sea-card.sea-row > div:nth-of-type(1) .sea-file-image,
  .sea-card.sea-row-history > div:nth-of-type(1) .sea-file-image {
    position: absolute;
    top: 10px;
    z-index: 1;
    /* margin-top: -10px; */
  }
  .has-thumbs .sea-card.sea-row > div:nth-of-type(1),
  .has-thumbs .sea-card.sea-row-history > div:nth-of-type(1) {
    flex: 0 1 50px;
    min-width: unset;
  }
  .has-thumbs .sea-card.sea-row > div:nth-of-type(1)::before,
  .has-thumbs .sea-card.sea-row-history > div:nth-of-type(1)::before {
    content: '';
    position: absolute;
    top: 10px;
    width: 40px;
    height: 40px;
    background-color: var(--text-on-white);
    border-radius: 6px;
    opacity: 0.08;
    padding: 0px;
    /* box-shadow: inset 0px 0px 16px rgba(0,0,0,1); */
  }
  .has-thumbs .sea-card.sea-row > div:nth-of-type(2),
  .has-thumbs .sea-card.sea-row-history > div:nth-of-type(2) {
    flex: 1 1;
    min-width: unset;
  }
  /* TODO: need style for no thumbs */

}

@media screen and (max-width: 719px) {
  .only-if-atleast-720 {
    display: none !important;
  }
}
