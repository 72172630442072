/* Extra padding for super admin */
.licensee-accounts .sea-row.headings {
    padding: 15px 4px 0px;
}
.licensee-accounts .sea-card {
    padding: 4px 15px;
}
.licensee-accounts .sea-card.sea-row {
    padding: 4px;
}

.licensee-accounts .sea-row, .accounts-summary {
    min-width: 950px;
}
.licensee-accounts .sea-row > div {
    padding-top: 8px;
    padding-bottom: 8px;
}

/* Column settings */
.licensee-accounts .sea-row > div:nth-of-type(3) { /* id */
    flex: 0 1 50px;
    text-align: right;
}
.licensee-accounts .sea-row > div:nth-of-type(5) { /* Status */
    flex: 0 1 180px;
}
.licensee-accounts .sea-row > div:nth-of-type(6) { /* region */
    flex: 0 1 90px;
    text-align: center;
    text-transform: uppercase;
}
.licensee-accounts .sea-row > div:nth-of-type(7) { /* time zone */
    flex: 0 1 140px;
}
.licensee-accounts .sea-row > div:nth-of-type(8) { /* vessels */
    flex: 0 1 90px;
    text-align: center;
}
.licensee-accounts .sea-row > div:nth-of-type(9) { /* offline */
    flex: 0 1 80px;
    text-align: center;
}
.licensee-accounts .sea-row > div:nth-of-type(10) { /* action */
    flex: 0 1 50px;
    text-align: right;
    padding: 0px 8px 0px 0px;
}

.account-actions  {
    --background: var(--ion-color-primary);
    --ion-item-background: var(--ion-color-primary);
}
ion-item {
    font-size: 13px;
}

.account-actions .line {
    background-color: rgba(255,255,255,0.5);
    height: 2px;
    margin-top: 4px;
    margin-bottom: 8px;
}

.accounts-summary .sea-card {
    background-color: var(--ion-color-primary);
    padding: 15px 32px;
    color: var(--icon-grey);
    font-size: 14px;
}
.accounts-summary .sea-card {
    display: flex;
}
.accounts-summary .sea-card > div {
    align-self: center;
}
.accounts-summary .sea-card > div:first-of-type {
    width: 60px;
}
.accounts-summary .sea-card > div:nth-of-type(3) {
    padding-left: 12px;
}
.accounts-summary .sea-card > div:last-of-type {
    flex-grow: 1;
    text-align: right;
    color: var(--ion-color-secondary);
}
.accounts-summary h2 {
    color: #fff;
}
.accounts-summary .line {
    background-color: var(--icon-grey);
    width: 1px;
    height: 18px;
}
