.lex-button {
    height: 36px;
    /* background-color: coral; */
    /* background-color: #fff; */
    background-color: var(--ion-color-light);
    color: var(--text-on-white);
    cursor: pointer;
    border-radius: var(--input-border-radius);
    font-size: 14px;
    line-height: 36px;
    padding: 0px 8px;
    display: inline-block;
    margin-right: 2px;
    transition: all 0s;
    white-space: nowrap;
}

.lex-button.disabled {
    opacity: 0.25;
    cursor: not-allowed;
    pointer-events: none;
}

.lex-button.active {
    background-color: #f5f5f5;
    box-shadow: inset 0px 0px 5px #aaa;
}

.mouse-device.lex-button:hover,
.mouse-device .lex-button:hover {
    background-color: #ddd;
    transition: all 0s;
}