.corrective-action-history {
    width: 100%;
}

.corrective-action-history .sea-row-history > div:nth-of-type(1) {
    /* Date Completed */
    flex: 0 1 100px;
}
.corrective-action-history .sea-row-history > div:nth-of-type(2) {
    /* Title */
    flex: 1 1;
}
.corrective-action-history .sea-row-history > div:nth-of-type(3) {
    /* Assigned to */
    flex: 0 1 100px;
}
.corrective-action-history .sea-row-history > div:nth-of-type(4) {
    /* Due date */
    flex: 0 1 100px;
}
.corrective-action-history .sea-row-history > div:nth-of-type(5) {
    /* Tags */
    flex: 0.5 1;
}
.corrective-action-history .sea-row-history > div:nth-of-type(6) {
    /* Image/Doc */
    flex: 0 1 56px;
}
.corrective-action-history .sea-row-history > div:nth-of-type(7) {
    /* Trash */
    flex: 0 1 32px;
}

@media screen and (max-width: 550px) {
    .corrective-action-history .sea-row-history > .date, /* Date Completed */
    .corrective-action-history .sea-row-history > .by,
    .corrective-action-history .sea-row-history > .item-title {
        /* Assigned to */
        padding-right: 44px;
    }
    .corrective-action-history .sea-row-history > .date::before {
        /* Date Completed */
        content: 'Date';
    }
    .corrective-action-history .sea-row-history > .by::before {
        /* Assigned to */
        content: 'Assigned';
    }
    .corrective-action-history .sea-row-history > .item-title::before {
        /* Title */
        content: 'Title';
    }
    .corrective-action-history .sea-row-history > .description::before {
        /* Description */
        content: 'Description';
    }
    .corrective-action-history .sea-row-history > .due-date::before {
        /* Due date */
        content: 'Due Date';
    }
    .corrective-action-history .sea-row-history > .tags::before {
        /* Tags */
        content: 'Tags';
    }
    .corrective-action-history .sea-row-history > .image {
        /* Image/Doc */
        height: 0px;
        padding: 0px;
    }
    .corrective-action-history .sea-row-history > .image {
        /* Image/Doc */
        height: 0px;
        padding: 0px;
    }
}
