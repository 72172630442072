.sea-divisions-modal .modal-box-container {
    justify-content: normal;
}

.sea-division-parent {
    padding: 0px 0px 4px 40px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.sea-division-options {
    padding: 4px 8px;
    width: 350px;
    max-width: 90vw;
}

.sea-division-option .name {
    padding: 8px 8px 8px 4px;
    font-size: 15px;
    cursor: pointer;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transition: background-color 0.2s;
}

.sea-division-option .name:hover {
    background-color: #f5f5f5;
}

.sea-division-option .folder-icon {
    padding: 7px 2px 0px 4px;
    color: var(--icon-grey);
    transition: all 0.3s ease-in-out;
}

.sea-division-option .folder-icon.closed {
    transform: rotate(-90deg);
}
