.edit-cf-button {
    display: block;
}

.edit-cf-disabled {
    display: none;
}

.conceal-vessels {
    opacity: 0;
    overflow: hidden;
    max-height: 0px;
    margin-top: -21px;
}
.reveal-vessels {
    opacity: 1;
    transition: opacity 0.2s ease-in;
    max-height: 2000px;
}



@media screen and (max-width: 999px) {
    .touch-device .edit-cf-disabled {
        display: block;
        color: var(--ion-color-danger);
        font-weight: bold;
        max-width: 300px;
        margin-top: 4px;
    }
    .touch-device .edit-cf-disabled ion-icon {
        vertical-align: bottom;
        margin-right: 4px;
    }
    .touch-device .edit-cf-button {
        opacity: 0.5;
        pointer-events: none;
    }
}
