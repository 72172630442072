
.sea-division {
    margin-left: 0px;
    width: 500px;
    max-width: 100%;
}

.sea-divisions {
    padding-bottom: 40px;
}

.sea-divisions .sea-row-history.sea-card {
    padding: 12px 14px 0px;
    min-height: 40px;
    margin: 5px 5px 5px 0px;
}

.sea-divisions .drag-handle img {
    min-width: 20px;
}

/* .sea-divisions .drag-handle.list {
    top: 0px;
} */
