.sea-trip-report {
    margin-top: 16px;
    overflow: hidden;
    transition: max-height 0.15s ease-in-out;
}
.sea-trip-report.off {
    max-height: 0px;
}
.sea-trip-report.on {
    max-height: 200px;
}

.action-panel-group {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.primary-actions {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
}

.delete-voyage-box {
    display: inline-block;
    margin: 0px 0px 0px 30px;
    /* position: relative;
    top: 2px; */
}

.delete-voyage-box .text.short {
    display: none;
}

.autosave-voyage-box {
    display: inline-flex;
    align-items: center;
    margin: 0px 0px 0px 8px;
}

.autosave-voyage-box.small {
    display: none;
}

.autosave-voyage-box .description {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.autosave-voyage-box .description > span {
    width: -moz-fit-content; /* Firefox support */
    width: fit-content;
    text-align: center;
}


.autosave-voyage-box .description .sea-help {
    display: flex;
    padding: 8px 0px 8px 12px !important;
}

.voyage-detail-tabs .sea-tabs-group {
    margin-top: 10px;
    margin-bottom: 17px;
}
.voyage-detail-tabs .sea-tabs-group.forms {
    margin-bottom: 8px;
}
.mouse-device .voyage-detail-tabs .sea-tabs-group {
    margin-top: 0px;
    margin-bottom: 0px;
}
.touch-device .voyage-detail-tabs .sea-tabs-group {
    margin-top: 6px;
}
.touch-device .voyage-detail-tabs .sea-tabs-container .tab-container {
    padding-top: 4px;
}

.total-pax-onboard {
    flex: 0 1 150px;
    max-width: 40%;
    padding-left: var(--grid-gutter);
}

.total-pax-onboard .sea-input {
    height: 100px;
    text-align: center;
    padding-top: 28px;
    background-color: #fff;
}

.total-pax-onboard .sea-input span {
    white-space: nowrap;
    font-size: 32px;
    opacity: 0.75;
}

.common-actions {
    max-height: 0px;
    transition: all 0.3s;
    overflow: hidden;
    opacity: 0;
}
.common-actions.show {
    max-height: 800px;
    opacity: 1;
}
.common-actions > div {
    padding: 16px 0px 16px 0px;
}
.common-actions > div > div {
    display: inline-block;
    padding: 8px 12px 8px 12px;
    border-radius: 17px;
    border-style: solid;
    font-weight: 500;
    margin: 0px 8px 8px 0px;
    border-width: 1px;
    background-color: var(--ion-color-light);
    border-color: var(--ion-color-primary);
    color: var(--ion-color-primary);
}


.action-panel-group .text.short {
    display: none;
}

@media screen and (max-width: 580px) {
}

@media screen and (max-width: 550px) {
    .action-panel-group .text.long {
        display: none;
    }
    .action-panel-group .text.short {
        display: flex;
    }
    .autosave-voyage-box.small {
        display: flex;
    }
    .autosave-voyage-box.large {
        display: none;
    }
    .primary-actions {
        flex: 1;
        width: 100%;
        justify-content: space-between;
    }
    .delete-voyage-box {
        margin: 0px;
    }
}


@media screen and (max-width: 420px) {
    .delete-voyage-box {
        margin-left: 0px;
    }

    .autosave-voyage-box .description {
        display: flex;
        align-items: center;
    }
    .autosave-voyage-box .description > span {
        display: none;
    }
}

@media screen and (max-width: 350px) {
    .delete-voyage-box .text {
        display: none;
    }
    .delete-voyage-box .sea-icon {
        display: flex;
    }
}