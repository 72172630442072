.corrective-actions {
    max-width: var(--max-table-width);
}

/* Column settings */
.corrective-actions .sea-row > div:nth-of-type(1) { /* img */
    flex: 0 1 56px;
}

.corrective-actions .sea-row > div:nth-of-type(2) { /* Title */
    flex: 1 1 0;
}

.corrective-actions .sea-row > div:nth-of-type(3) { /* Action num */
    flex: 0 1 90px;
    text-align: center;
}

.corrective-actions .sea-row > div:nth-of-type(4) { /* Assigned To */
    flex: 0.4 1 0;
}

.corrective-actions .sea-row > div:nth-of-type(5) { /* Tags */
    flex: 0.4 1 0;
}

.corrective-actions .sea-row > div:nth-of-type(6) { /* Date Added */
    flex: 0 1 90px;
}

.corrective-actions .sea-row > div:nth-of-type(7) { /* Due Date */
    flex: 0 1 90px;
}

.corrective-actions .sea-row > div:nth-of-type(8) { /* Vessel */
    flex: 0.4 1 0;
}

@media screen and (max-width: 400px) {
    .corrective-actions.page-head {
        flex-direction: column;
    }

    .corrective-actions.page-head .actions {
        justify-content: flex-start;
    }
}


@media screen and (max-width: 1080px) {
    .corrective-actions .sea-row > div:nth-of-type(4) { /* Assigned To */
        flex: 0.6 1 0;
    }

    .corrective-actions .sea-row > div:nth-of-type(5) { /* Tags */
        flex: 0.5 1 0;
    }

    .corrective-actions .sea-row > div:nth-of-type(6) { /* Date Added */
        display: none;
    }
}

@media screen and (max-width: 820px) {
    .corrective-actions .sea-row > div:nth-of-type(5) { /* Tags */
        display: none;
    }

    .corrective-actions .sea-row > div:nth-of-type(6) { /* Date Added */
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .show-menu.desktop-view .corrective-actions .sea-row > div:nth-of-type(4) { /* Assigned To */
        flex: 0.6 1 0;
    }

    .show-menu.desktop-view .corrective-actions .sea-row > div:nth-of-type(5) { /* Tags */
        flex: 0.5 1 0;
    }

    .show-menu.desktop-view .corrective-actions .sea-row > div:nth-of-type(6) { /* Date Added */
        display: none;
    }
}

@media screen and (max-width: 1040px) {
    .show-menu.desktop-view .corrective-actions .sea-row > div:nth-of-type(5) { /* Tags */
        display: none;
    }
}


@media screen and (max-width: 550px) {
    .corrective-actions .sea-row.sea-card > div::before {
        width: 93px;
    }

    .corrective-actions .sea-row > div:nth-of-type(3) {
        text-align: left;
    }

    .corrective-actions .sea-row > div:nth-of-type(3)::before {
        content: 'Action #';
    }

    .corrective-actions .sea-row > div:nth-of-type(4)::before {
        content: 'Assigned';
    }

    .corrective-actions .sea-row > div:nth-of-type(5)::before {
        content: 'Tags';
    }

    .corrective-actions .sea-row > div:nth-of-type(7)::before {
        content: 'Due Date';
    }
}
