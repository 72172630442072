.file-manager-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    z-index: 1000;
}

.sea-spinner {
    margin-bottom: 10px;
}

.file-manager-spinner-text {
    text-align: center;
    color: #fff;
    font-size: 16px;
}