.new-message.reveal .sea-error-report {
    position: relative;
    top: 0px;
    transition: top 0.2s;
}
.new-message.conceal .sea-error-report {
    position: relative;
    top: 40px;
    transition: top 0.2s;
}
