.associated-tasks-history.scheduled .sea-row-history > div:nth-of-type(1) { /* Task */
    flex: 1 1;
}
.associated-tasks-history.scheduled .sea-row-history > div:nth-of-type(2) { /* Interval */
    flex: 0 1 90px;
}
.associated-tasks-history.scheduled .sea-row-history > div:nth-of-type(3) { /* Next Due */
    flex: 0 1 85px;
}
.associated-tasks-history.scheduled .sea-row-history > div:nth-of-type(4) { /* Estimated Time */
    flex: 0 1 100px;
}
.associated-tasks-history.scheduled .sea-row-history > div:nth-of-type(5) { /* Status */
    flex: 0 1 140px;
}

.associated-tasks-history.job-list .sea-row-history > div:nth-of-type(1) { /* Task */
    flex: 1 1;
}
.associated-tasks-history.job-list .sea-row-history > div:nth-of-type(2) { /* Interval */
    flex: 0 1 90px;
}
.associated-tasks-history.job-list .sea-row-history > div:nth-of-type(3) { /* Next Due */
    flex: 0 1 85px;
}
.associated-tasks-history.job-list .sea-row-history > div:nth-of-type(4) { /* Estimated Time */
    flex: 0 1 100px;
}
.associated-tasks-history.job-list .sea-row-history > div:nth-of-type(5) { /* Status */
    flex: 0 1 140px;
}



.equipment-tasks-history .sea-row-history > div:nth-of-type(2) { /* Task */
    flex: 1 1;
}
.equipment-tasks-history .sea-row-history > div:nth-of-type(3) { /* Notes */
    flex: 1 1;
}
.equipment-tasks-history .sea-row-history > div:nth-of-type(4) { /* Type */
    flex: 0 1 105px;
}
.equipment-tasks-history .sea-row-history > div:nth-of-type(5) { /* Completed by */
    flex: 0.75 1;
}
/*.equipment-tasks-history .sea-row-history > div:nth-of-type(6) { !* Image/Doc *!*/
    .equipment-tasks-history .sea-row-history .task-image { /* Image/Doc */
    flex: 0 1 56px;
}


.spare-parts-history .sea-row-history > div:nth-of-type(1) { /* Image/Doc */
    flex: 0 1 56px;
}
.spare-parts-history .sea-row-history > div:nth-of-type(2) { /* Item */
    flex: 1.5 1;
}
.spare-parts-history .sea-row-history > div:nth-of-type(3) { /* Quantity */
    flex: 0 1 90px;
    text-align: center;
}
.spare-parts-history .sea-row-history > div:nth-of-type(4) { /* Location */
    flex: 1 1;
}
.spare-parts-history .sea-row-history > div:nth-of-type(5) { /* Part # */
    flex: 1 1;
}

.add-task-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}




@media screen and (max-width: 550px) {
    .associated-tasks-history .sea-row-history > div:nth-of-type(1) { /* Task */
        font-weight: 700;
    }
    .associated-tasks-history {
        --label-width: 70px;
    }
    .associated-tasks-history .sea-row-history > div:nth-of-type(2)::before {
        content: 'Interval';
    }
    .associated-tasks-history .sea-row-history > div:nth-of-type(3)::before {
        content: 'Next Due';
    }


    .equipment-tasks-history {
        --label-width: 70px;
    }
    .equipment-tasks-history .sea-card.sea-row-history > div::before {
        width: var(--label-width);
    }
    .equipment-tasks-history .sea-row-history > div:nth-of-type(1)::before {
        content: 'Date';
    }
    .equipment-tasks-history .sea-row-history > div:nth-of-type(2)::before {
        content: 'Task';
    }
    .equipment-tasks-history .sea-row-history > div:nth-of-type(3)::before {
        content: 'Notes';
    }
    .equipment-tasks-history .sea-row-history > div:nth-of-type(4)::before {
        content: 'Type';
    }
    .equipment-tasks-history .sea-row-history > div:nth-of-type(5)::before {
        content: 'By';
    }
    .equipment-tasks-history .sea-row-history > div:nth-of-type(1),
    .equipment-tasks-history .sea-row-history > div:nth-of-type(2) {
        padding-right: 44px;
    }
    .equipment-tasks-history .sea-row-history > div:nth-of-type(6) > .sea-file-image {
        position: absolute;
        top: 10px;
        right: 8px;
    }

    .spare-parts-history .sea-row-history > div:nth-of-type(2) {
        font-weight: 600;
    }
    .spare-parts-history .sea-row-history > div:nth-of-type(3) {
        text-align: left;
    }
    .spare-parts-history .sea-row-history > div:nth-of-type(3)::before {
        content: 'Quantity';
    }
    .spare-parts-history .sea-row-history > div:nth-of-type(4)::before {
        content: 'Location';
    }
    .spare-parts-history .sea-row-history > div:nth-of-type(5)::before {
        content: 'Part #';
    }

}
