
.sea-tabs-group-parent {
  display: flex;
  padding-right: var(--modal-padding-horizontal);
  column-gap: 4px;
  overflow-x: auto;
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  ms-overflow-style: none; /* Hides scrollbar in IE/Edge */
}

.sea-tabs-group-parent::-webkit-scrollbar { /* Hides scrollbar in Chrome/Safari */
  display: none;
}

.sea-tabs-group {
  display: flex;
  margin-top: 20px;
  padding-bottom: 8px;
  margin-bottom: 17px;
  overflow-x: auto;
  position: relative;
  left: calc(-1 * var(--page-padding-horizontal));
  padding-left: var(--page-padding-horizontal);
  width: calc(100% + var(--page-padding-horizontal) + var(--page-padding-horizontal));
  scroll-behavior: smooth;
}
.sea-modal .sea-tabs-group {
  left: calc(-1 * var(--modal-padding-horizontal));
  padding-left: var(--modal-padding-horizontal);
  width: calc(100% + var(--modal-padding-horizontal) + var(--modal-padding-horizontal));
}
.sea-tabs-group.forms {
  margin-bottom: 8px;
}
.sea-tabs-group > div {
  margin-right: 4px;
}
.sea-tabs-group > div:last-child {
  margin-right: 0px;
}

.sea-tabs-container {
  position: relative;
}
.sea-tabs-fade {
  position: absolute;
  top: 0px;
  width: 30px; /* (to match page shadow gradient) */
  height: 100%;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
}
.sea-tabs-fade.on {
  opacity: 1;
}
.sea-tabs-fade.left {
  left: calc(-1 * var(--page-padding-horizontal));
  background: linear-gradient(
    90deg,
    rgba(109, 111, 120, 1.0), /* (to match page shadow gradient) */
    rgba(var(--bg-grey-rgb), 0.0)
  );
}
.sea-tabs-fade.right {
  right: calc(-1 * var(--page-padding-horizontal));
  background: linear-gradient(
    90deg,
    rgba(var(--bg-grey-rgb), 0.0),
    rgba(var(--bg-grey-rgb), 1.0)
  );
}
.sea-modal .sea-tabs-fade.left {
  left: calc(-1 * var(--modal-padding-horizontal));
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1.0), /* (to match page shadow gradient) */
    rgba(255, 255, 255, 0.0)
  );
}
.sea-modal .sea-tabs-fade.right {
  right: calc(-1 * var(--modal-padding-horizontal));
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.0), /* (to match page shadow gradient) */
    rgba(255, 255, 255, 1.0)
  );
}
.end-bit > div {
  width: var(--page-padding-horizontal);
}
.sea-modal .end-bit > div {
  width: var(--modal-padding-horizontal);
}
.mouse-device .sea-tabs-fade {
  display: none;
}
.mouse-device .sea-tabs-group {
  overflow-x: unset;
  margin-top: 10px;
  display: block;
}
.sea-modal .sea-tabs-group {
  padding-right: calc(var(--modal-padding-horizontal) - 4px);
}
.mouse-device .sea-modal .sea-tabs-group {
  padding-right: calc(2 * var(--modal-padding-horizontal));
}
.mouse-device .columns .sea-tabs-group {
  margin-top: -4px;
}
.mouse-device .sea-tabs-group > div {
  padding-top: 10px;
  display: inline-block;
  vertical-align: middle;
}
.mouse-device .desktop-view .sea-tabs-group > div {
  margin-right: 4px;
}
.mouse-device .end-bit {
  display: none !important;
}

.sea-plus-button {
  color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
  border-style: solid;
  border-width: 2px;
  width: 32px;
  height: 32px;
  border-radius: 17px;
}
.sea-plus-button ion-icon {
  position: relative;
  top: -1px;
  left: -1px;
}
.sea-plus-button.disabled {
  opacity: 0.25;
}
.sea-tabs-group .sea-plus-button {
  margin-top: 1px;
  margin-left: 8px;
}

.sea-draggable-tab.drag-over {
  opacity: 0.5;
  outline: 2px dashed var(--ion-color-secondary);
  outline-offset: -2px;
  box-sizing: border-box;
}
.sea-draggable-tab.drag-over > ion-button {
  opacity: 0.5;
}

@media screen and (min-width: 851px) {
  .show-menu .sea-tabs-group.page {
    visibility: hidden;
    height: 4px;
    margin: 0px;
  }
}

@media screen and (min-width: 1301px) {
  .sea-tabs-group.page {
    visibility: hidden;
    height: 4px;
    margin: 0px;
  }
}
