/* Column settings */
.error-reports.server-events .sea-row > div:nth-of-type(1) { /* When Event */
    flex: 0 1 150px;
}
.error-reports.server-events .sea-row > div:nth-of-type(2) { /* Type */
    flex: 0 1 150px;
}
.error-reports.server-events .sea-row > div:nth-of-type(3) { /* Title */
    flex: 1 1 0;
}
.error-reports.server-events .sea-row > div:nth-of-type(4) { /* Content */
    flex: 1.5 1 0;
}
.error-reports.server-events .sea-row > div:nth-of-type(5) { /* When Expires */
    flex: 0 1 150px;
}
