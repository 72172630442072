
.sea-pdf-viewer.document-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    object-fit: contain;
    max-width: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.sea-pdf-viewer.document-container.center-document {
    display: flex;
    justify-content: center;
    align-items: center;
}
 
.sea-pdf-viewer.document-container.medium {
    max-width: 200px;
    max-height: 283px;
    align-items: right;
}

.sea-pdf-viewer.document-container.tiny2 {
    display: inline-block;
    max-width: var(--tiny-thumb-size);
    max-height: var(--tiny-thumb-size);
    min-height: 28px;
    object-fit: contain;
    margin: 0px 6px 6px 0px;
}

.sea-pdf-viewer.document-container.tiny {
    display: flex;
    max-width: 56px;
    max-height: 56px;
    min-height: var(--tiny-thumb-size);
    object-fit: contain;
    overflow: hidden;
    margin: 0px;
    /* margin-top: 6px; */
}
  
.sea-pdf-viewer .page-container {
    flex: 1;
    display: flex;
    justify-content: center;
}

.sea-pdf-viewer .hidden {
    display: none;
    opacity: 0;
}

.sea-file-image .sea-pdf-viewer.document-container.medium {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-viewer-flex-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* position: absolute; */
  }


  .pdf-viewer .sea-spinner-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .a4-aspect {
    aspect-ratio: 1 / 1.414;
  }

